/*
 * @file header.less
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Tuesday, 26th November 2019 4:49:51 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */
// QC: HOLD

@keyframes rotatingY {
  from { transform: rotateY(0deg) }
  to { transform: rotateY(360deg) }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}


@keyframes slideInFromTop {
  0% {
    transform: translateY(-1*@headerHeight);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-1*@sidebarWidth);
    opacity: 0;
  }  
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#header-region {
  display: flex;
  width: inherit;
  min-height: @headerHeight;
  max-height: @headerHeight;
  background: @toolbarBackground;
  color: @toolbarColor;  
  justify-content: space-between;
  animation: 1s ease-out 0s 1 slideInFromTop;  
  will-change: transform, opacity;
  
  #branding-region {
    width: @sidebarWidth;
    min-width: @sidebarWidth;
    display: inherit;
    align-items: center;
    justify-content: center;
    height: @headerHeight;
    box-sizing: border-box;
    animation: 1s ease-out 0s 1 slideInFromLeft;  

    #logo {
      width: 3.75rem;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(@icons_logo_dark_compact);
      background-size: contain;

      @media @smartphones {
        width: 28px;
        background-image: url(@icons_logo_dark_sign);
      }
    }  
  }

  @media @smartphones {
    #branding-region {
      width: 60px;
      min-width: 60px;
    }
  }

  .rotating {
    animation: rotatingY 0.6s linear infinite;
  }

  #toolbar-region {
    width: inherit;
    display: inherit;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    
    .toolbar-buttons {
      margin: 0 @spaceHalf;

      .buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .separator {
          display: flex;
          background: darken(@backgroundHeader, 5%);
          width: 1px;
          height: 100%;
          margin: 0 @spaceMini;
        }

        .toolbar-button {
          font-size: 1rem;
          display: flex;
          cursor: pointer;
          margin-inline-end: 1px;          
          width: 3rem;
          height: 3rem;
          align-items: center;
          justify-content: center;
          animation: .5s slowshow;

          .circle {
            position: absolute;
            background: @secondary;
            border-radius: 100%;
            animation: scaleIn 0.4s infinite cubic-bezier(.36, .11, .89, .32);
            width: inherit;
            height: inherit;
            opacity: 0;
          }

          &.global {
            .icon { 
              color: @secondary;
            }  
          }

          .icon {
            color: @toolbarColor;
            width: 1rem;
            transition: all .1s ease-in-out;
            &:hover {
              transform: scale(1.1);
              color: @toolbarColorHover;
            }
          }

          &.spinner {
            cursor: wait;
          }

          &:hover {
            &:not(.disabled) {
              .icon {
                color: @colorTextOpposite;
              }
            }
          }

          &:active, &.active {
            &:not(.disabled) {
              .icon {
                color: @secondary;
              }

              &:hover {
                .icon {
                  color: @secondary; 
                }
              }
            }
          }

          &.disabled {
            color: lighten(@backgroundHeader, 10%);
            .icon {
              color: lighten(@backgroundHeader, 10%);
            }
          }
        }
      }
    }
	}
}