/*
 * @file widgets.less
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Wednesday, 17th February 2021 10:34:35 am
 * @copyright 2015 - 2021 SKAT LLC, Delive LLC
 * @flow
 */
@import url('./_variables.less');


.widget-factory {
  background-color: #002c4e !important;
  .buttons {
    border: none !important
  }
}

.blueprint-desk { 
  background: #63bdff;
  background-image: linear-gradient(lightblue 1px, transparent 1px), linear-gradient(90deg, lightblue 1px, transparent 1px);
  background-size: @unit * 1.5 @unit * 1.5;
  background-position: @unit @unit;
}

@keyframes infinite-spinning {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.flex() {
  display: flex;
}

.spin () {
  animation: infinite-spinning 1s ease-out 0s infinite normal;
}

.interactiveHoverOpacity () {
  opacity: .7;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}

.pushes {
  margin-inline-end: @unit * 0.5;
}

.curved {
  border-radius: 2px;
}

.bordered {
  /* 
  Не используется border потому-что он дает разбросы в итоговых размерах
  подмешивает 1px к размерностям вроде 1.5rem, в итоге при масштабировании
  начинают подмешиваться побочные эффекты в виде вылазящих их разных
  мест отступов или обрезанных краев
  */
  box-shadow: 0 0 0 1px black;
}

.full-centered {
  .flex();
  align-items: center;
  justify-content: center
}

.widget-row {
  .flex();
  .border-boxed();
  align-items: center;
  min-height: @unit * 1.5;
  padding: 0 @unit * 0.25;
}

.widget-col {
  .flex();
  .border-boxed();
  align-items: center;
  min-height: @unit * 1.5;
  padding: @unit * 0.25 0;
}

.widget-icon {  
  .full-centered();
  .border-boxed();     
  color: @colorText;  
  height: @unit * 1.5;
  width: @unit * 1.5;
  
  &.standalone {    
    .interactiveHoverOpacity();
    padding: @unit * 0.25;
    height: @unit * 1.5;
    width: @unit * 1.5; 
  }

  &.inverted {
    color: @colorTextOpposite;
  }

  &.spin:hover {
    .spin();
  }
}

.widget-label {
  .full-centered();

  &.standalone {    
    .pushes();
    width: fit-content;
    height: @unit * 1.5;
  }

  &.inverted {
    color: @colorTextOpposite;
  }
}

.widget-action {  
  .full-centered();
  .border-boxed();  
  .curved();
  .interactiveHoverOpacity();
    
  height: @unit * 1.25;
  width: auto;
  min-width: @unit * 1.25;  
  color: @colorTextOpposite;
  background-color: @backgroundButton;
  
  margin-inline-end: @unit * .2;

  &.standalone {
    .pushes();
    .bordered();    
    height: @unit * 1.5;
    min-width: @unit * 1.5;
  }

  &.spin {
    &:hover {
      .widget-icon {
        .spin()
      }
    }
  }
}

.widget-input {
  .flex();
  .curved();
  .bordered();

  font-size: 0.75rem;
  font-family: @fontFamily; 
  height: @unit * 1.5;

  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: @backgroundInput;

  input {
    height: @unit * 1.5;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }
}