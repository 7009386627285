// QC: FAILED
@import '_variables.less';
// @import '_dark.less';

@font-face {
  font-family: Accented;
  src: url('../fonts/NunitoSans-Bold.ttf');
}

@font-face {
  font-family: Normal;
  src: url('../fonts/NunitoSans-Regular.ttf');
}

@keyframes blink {
  from { color: @backgroundWarning; }
  to { color: @backgroundError; }
}

@keyframes blink-to-normal {
  from {
    background: @backgroundWarning;
    color: @colorTextOpposite;
  }
  to {
    background: @background;
    color: @colorText;    
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: @scrollbarColor @scrollbarBackground;

  &::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  &::-webkit-scrollbar-track {
    background: @scrollbarBackground;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @scrollbarColor;
    border-radius: 6px;
    border: 2px solid @scrollbarBackground;
  }  
}


html {
  height: 100%;
  width: 100%;
  overflow: clip;
  background: @background;
  min-width: 1024px;
  min-height: 648px;
  box-sizing: border-box;
  
  font-size: @remUnit; 
  font-family: Normal, sans-serif;
  visibility: visible !important;

  @media @smartphones {
    font-size: 18px;
    min-width: auto;
    min-height: auto;
  }

  #preloader {
    display: none;
  }

  body {
    padding: 0;
    margin: 0;
    height: inherit;
    width: inherit;
    font-size: 0.75rem;
    background: @background;
    color: @colorText;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: clip;
    a {
      color: @colorLink;
    }
  }

  &.rtl {
    direction: rtl;
  }
}

.ltr {
  direction: ltr !important;
}


ol {
  margin-inline-start: 1rem;
}

.pre-line {
  white-space: pre-line;
}

disabled[role=region] {
  &:hover {
    outline: dashed 1px red;
    &::before {
      content: "#" attr(id);
      position: absolute;
      color: red;
      margin: .25rem;
      font-family: monospace;
      font-size: 10px;
      z-index: 1000;
      min-width: 8rem;
      text-align: right;
      height: 2rem;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      margin-block-start: 2rem;
      margin-inline-start: 1rem;
      border-left: 1px solid red;
      border-bottom: 1px solid red;
    }
  }
}

embed {
  max-height: 0;
  max-width:0;
  position: absolute;
}

input, textarea {
  font-family: Normal, sans-serif;
  color: @colorText;
}

input[type=text], input[type=password] {
  text-indent: 0.25em;
}

input[type=text], input[type=password], textarea, passholder, .for-checkbox {
  &:focus {
    outline: 1px solid @secondary;
    outline-offset: -1px;    
  }
}


.select-style select {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  padding: .5em;
  width: 130%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-style select:focus {
  outline: none;
}

/**
* Переиспользуемые класыы
*/
.clear {
  clear: both;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
  overflow: hidden;
  width: 0 !important;
  height: 0 !important;
  position: absolute;
}

.no-overflow {
  overflow: hidden !important;
}

.auto-overflow {
  overflow: auto !important;
}

.conceal {
  transform: translateY(100vh) !important;
  transition: none !important;
  contain: content;
}

.visible {
  visibility: visible;
}

.c-a, .a-c, .center-aligned {
  text-align: center;
}

.c-a-f {
  justify-content: center !important;
  align-items: center;
  display: flex;
}

.r-a, .a-r, .right-aligned {
  text-align: right;
}

.flex-right-aligned {
  justify-content: flex-end;
}

.left-spaced {
  margin-inline-start: .5em;
}

.right-spaced {
  margin-inline-end: .5em;
}

h1, h2, h3, h4, h5, .title, i.fa-solid {
  color: @colorTitle;
}

.expander {
  flex-grow: 2;
}

.accented {
  background: @backgroundError;
  color: @colorTextOpposite;
}

.accented-font {
  font-family: Accented;
}

.dashed, .call-link {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: pointer;
}

h1, h2, h3, h4, h5, .title {
  font-weight: normal;
  font-family: Accented, sans-serif;
}

h1 { font-size: 1.5rem; }
h2 { font-size: 1.275rem; }
h3 { font-size: 1.125rem; }
h4 { font-size: 0.9rem; }

.small {
  font-size: 0.7rem;
}

.un-fade {
  opacity: 1;
  .transition(opacity .15s ease-in-out);
}

.fade {
  opacity: 0;
  .transition(opacity .15s ease-in-out);
}

.smooth {
  .transition(opacity .15s ease-in-out);
}

.faded {
  opacity: 0;
}

form input[type=button], .button {
  padding: 0.45em 0.5em;
}

.button {
  user-select: none;
  height: @space*2;
  min-width: @space * 2;

  &.iconized {
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
    span.button-label {
      padding: 0 @spaceHalf;
    }

    .icon-box {
      width: @space * 2;
      min-width: @space * 2;
      height: inherit;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      background-color: darken(@backgroundButton, 20%);
      
      &.active {
        background-color: darken(@secondary, 10%);
      }
    }
    
    &.isPending {
      .icon-box {
        .pending()
      }      
    }
    &.isPrimary {
      .icon-box {
        background-color: darken(@secondary, 20%);       
      }   
    }
  }

  &:focus {
    outline: solid 1px @primary;
    outline-offset: 2px;
    box-shadow: 0px 0px 2px @primary;
  }
}

@keyframes endlessMove {
  0% { background-position: 100% 100% }
  100% { background-position: 0% 0% }
}

.pending {
  background: linear-gradient(-45deg, @primary 25%,@primary 25%,@secondary 25%,@secondary 50%,@primary  50%, @primary  75%,@secondary 75%, @secondary 100%);
  background-size: 200% 200%;
  animation: endlessMove .3s linear infinite;
  opacity: .5;
}

body {
  .pending, .isPending {
    .pending()
  }
}

.icon-wrapper {
  display: inline-flex;
}

.icon.warn-icon {
  color: peru
}

input[type=button], button, .button {
  background-color: @backgroundButton;
  color: @colorTextButton;
  box-shadow: 1px 1px 0px @boxShadowColor;
  white-space: nowrap;  
  border: none;
  border-radius: 1px;  
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: .5rem .75rem;
  font-size: 1em;
  transition: background-color .1s ease-in-out, box-shadow .3s ease-in-out; 

  .icon {
    color: @colorTextButton;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: darken(@backgroundButton, 10%);    
  }

  &:active, &.isActive {
    background-color: @secondary;
    box-shadow: inset 1px 1px 0px @boxShadowColor;
  }


  &.primary, &.isPrimary {
    background-color: darken(@secondary, 10%);
    color: @colorTextOpposite;

    &:hover {
      background-color: darken(@secondary, 20%);
    }
  }

  &.disabled, &.disabled:hover, &.disabled:active {
    background-color: @backgroundButton;
    opacity: .3;
  }
}
 
.warning {
  background-color: peru;
  color: @colorTextOpposite;
}

input::-moz-focus-inner {
  border: 0;
}

.ui-state-focus {
  box-shadow: 1px 1px 0px @secondary, -1px -1px 0px @secondary;
}

.ui-selectmenu-menu {
  ul {
    max-height: 20em;
    overflow-y: auto;
  }
}

input[type=text], input[type=password], input[type=number], textarea {
  box-sizing: border-box;
  background: @backgroundInput;
  border: solid 1px @borderColor;
  height: @inputHeight;
  padding: @spaceMini;
  font-size: 1em;

  &:focus {
    outline: solid 1px @secondary;
  }
}

textarea {
  box-sizing: border-box;
}

label input[type=checkbox], label input[type=radio] {
  visibility: hidden;
  display:block;
  height:0;
  width:0;
  position:absolute;
  overflow:hidden;
  cursor: pointer;
}

label.for-checkbox, label.for-radio {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center; 
}

label span.checkbox, label span.radio {
  height: 0.75rem;
  width: 0.75rem;
  min-width: 0.75rem;
  max-width: 0.75rem;
  outline: solid @borderSize @colorIcon;
  outline-offset: 0px;
  display: inline-block;
  margin-inline-end: @spaceHalf;
  margin-inline-start: 2px; //outline and outline offset
  margin-block-end: 2px;
  margin-block-start: 2px; //outline and outline offset
  cursor: pointer;
  vertical-align: text-top;
  background: @background;
  transition: background-color .15s ease-in-out, outline-offset .15s ease-in-out;
}

.reversed {
  label.for-checkbox, label.for-radio {
    flex-direction: row-reverse;
  }
  label span.checkbox, label span.radio {
    margin-inline-start: @spaceHalf;
    margin-inline-end: 2px;
  }
}


label span.checkbox.disabled {
  background: @borderColor;
  color: lighten(@borderColor, 20%);  
  outline: solid @borderSize lighten(@colorIcon, 20%);  
}

label span.radio {
  border-radius: 1em;
}

[type=checkbox]:checked + span {
  cursor: pointer;
  background: @colorIcon;
  outline-offset: 1px;
}

[type=radio][disabled] + span {
  background: #e4ebee;
  color: lighten(#6a6c6f, 20%);
  border-color: lighten(@borderColor, 10%);
}

[type=radio]:checked + span {
  cursor: pointer;

  .dot {
    border-radius: 0.5em;
    background: @colorIcon;
    display: block;
    height: 0.5em;
    left: 0.24em;
    position: relative;
    top: 0.25em;
    width: 0.55em;
  }
}

.rtl {
  [type=radio]:checked + span {
    .dot {
      left: unset;
      right: 0.24em;
    }
  }
}

input.primary {
  color: @colorTextOpposite;
  background-color: @backgroundPrimaryButton;

  &:hover {
    background-color: lighten(@backgroundPrimaryButton, 10%);
  }
}

.input {
  border: solid 1px #bbbbbb;
  display: flex;
  align-items: stretch;

  input {
    border: 0;
    margin: 1px;
    padding: 0;
    overflow: hidden;
    width: 100%;
  }

  input:focus {
    box-shadow: 0;
  }

  .button {
    border-left: 1px solid #bbbbbb;
    width: 3.2em;
  }
}

.focus {
  outline: 1px solid orange;
}

input[readonly], input:disabled, input:disabled:hover {
  opacity: .3;
}

.width-100, .v100, .full-width {
  width: 100%;
}

.width-99 {
  width: 99%;
}
 
.spinner {
  cursor: wait !important;
}

textarea {
  resize:none;
}

.green {
  color: green !important;
}
.red {
  color: red !important;
}

.processing {
  cursor: wait;
}

.no-padding {
  padding: 0 !important;
}

.dynamic-select {
  width: 100%;
}

.row {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  margin: @spaceMini 0;

  &.adaptive-dual {
    flex-wrap: wrap;
    @media @smartphones {
      .col.col-6, .col.col-5, .col.col-7, .col.col-4, .col.col-8, .col.col-3, .col.col-9 { 
        width: 100%;
        margin: @spaceMini 0;
      } 
    }
  }


  &.adaptive-tripple {
    flex-wrap: wrap;
    @media @smartphones {
      .col.col-4 { 
        width: 100%;
        margin: @spaceMini 0;
      } 
    }
  }

  &.adaptive-quad {
    flex-wrap: wrap;
    @media @smartphones {
      .col.col-3 { 
        width: 50%;
        margin: @spaceMini 0;
      } 
    }
  }

  &.adaptive-hex {
    flex-wrap: wrap;
    @media @smartphones {
      .col.col-2 { 
        width: 50%;
        margin: @spaceMini 0;
      } 
    }
  }


  .col {
    box-sizing: border-box;
    padding: 0 @spaceMicro;

    input {
      max-width: 100%;
    }

    &.col- {  
        &12 { width: 100% }
        &11 { width: 91.6% }
        &10 { width: 83.3% }
        &9 { width: 75% }
        &8 { width: 66.6% }
        &7 { width: 58.3% }
        &6 { width: 50% }
        &5 { width: 41.5% }
        &4 { width: 33.3% }
        &3 { width: 25% }
        &2 { width: 16.6% }
        &1 { width: 8.3% }
      }
    }
}

.no-margin {
  margin: 0
}
.no-left-margin {
  margin-inline-start: 0
}
 
.no-top-margin {
  margin-block-start: 0
}

.no-right-margin {
  margin-inline-end: 0
}

.no-botton-margin {
  margin-block-end: 0
}

.no-border {
  border: none;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.h100, .full-height {
  height: 100%;
}

.h200px {
  height: 200px;  
  @media @smartphones {
    height: 100%;
  }
}

.h300px {
  height: 300px;  
  @media @smartphones {
    height: 100%;
  }
}

.h400px {
  height: 400px;  
  @media @smartphones {
    height: 100%;
  }  
}

.h40vh {
  height: 40vh;  
  @media @smartphones {
    height: 100%;
  }  
}

.h30vh {
  height: 30vh;  
  @media @smartphones {
    height: 100%;
  }  
}

.h600px {
  height: 600px;
  @media @smartphones {
    height: 100%;
  }
}

.h800px {
  height: 800px;
  @media @smartphones {
    height: 100%;
  }
}


.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item.changable > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item.changable > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: -3px;
  bottom: -3px;
  width: 15px;
  height: 15px;
  background: black;
  .flashes()
}

.a-v, .v-a {
  display: flex;
  align-items: center;
}

.a-h, .h-a {
  display: flex;
  justify-content: center;
}

.h-a-important {
  display: flex;
  justify-content: center !important;
}

.half-padding {
  padding: @spaceHalf;
}

.half-top-padding {
  padding-top: @spaceHalf;
}

.half-bottom-padding {
  padding-bottom: @spaceHalf;
}

.half-right-padding {
  padding-right: @spaceHalf;
}

.half-left-padding {
  padding-left: @spaceHalf;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.full-padding {
  padding: @space;
}

.mini-padding {
  padding: @spaceMini;
}

.mini-top-padding {
  padding-top: @spaceMini;
}

.mini-bottom-padding {
  padding-bottom: @spaceMini;
}

.mini-left-padding {
  padding-left: @spaceMini;
}


.mini-right-padding {
  padding-right: @spaceMini;
}

.no-top-padding {
  padding-top: 0;
}

.full-margin {
  margin: @space;
}

.half-margin {
  margin: @spaceHalf;
}

.mini-margin {
  margin: @spaceMini;
}

.full-bottom-margin {
  margin-block-end: @space;
}

.full-top-margin {
  margin-block-start: @space;
}

.half-top-margin {
  margin-block-start: @spaceHalf;
}

.half-bottom-margin {
  margin-block-end: @spaceHalf;
}

.half-left-margin {
  margin-inline-start: @spaceHalf;
}

.half-right-margin {
  margin-inline-end: @spaceHalf;
}

.full-right-margin {
  margin-inline-end: @space;
}

.full-left-margin {
  margin-inline-start: @space;
}

.mini-left-margin {
  margin-inline-start: @spaceMini;
}

.micro-margin {
  margin: @spaceMicro;
}
.micro-top-margin {
  margin-block-start: @spaceMicro;
}
.micro-left-margin {
  margin-inline-start: @spaceMicro;
}
.micro-right-margin {
  margin-inline-end: @spaceMicro;
}
.micro-bottom-margin {
  margin-block-end: @spaceMicro;
}

.border-boxed {
  box-sizing: border-box
}

#context-region {
  display: flex;
  padding: 0 @spaceHalf;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;

  .field {
    margin-inline-end: @spaceHalf
  }
}

div.positive, span.positive {
  background-color: #17bb17;
}

div.negative, div.negative {
  background-color: #a71a1a;
}

div.positive-color {
  color: #17bb17 !important;
}

div.negative-color {
  color: #a71a1a !important;
}

.non-flex {
  flex: none;
}

.bold {
  font-weight: bold
}

.uppercase {
  text-transform: uppercase;
}

.muted {
  color: @colorTextMuted;
  .checkbox {
    outline-color: @colorTextMuted;
  }
}

.minimal-text-size {
  font-size: 9px;
}

.w10rem {
  width: 10rem;
}

.object-string {
  background: lighten(@primary, 60%);
  color: @colorText;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: @spaceMini !important;
  overflow: hidden;
}


.flashes {
  animation: flashes 0.5s linear infinite;
}

.react-grid-item.changable {
  animation: slowshow 0.25s cubic-bezier(1,0,1,0);
}

@keyframes animated-text {
  from { width: 0; }
  to { width: 480px; }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes flashes {
  50% {
    filter: invert(1);
  }
}

@keyframes slowshow {
  from { opacity: 0; }
  to { opacity: 1; }
}
 
.location-view {
  .h400px();
  @media @smartphones {
    .h100();
  }
}

.react-contextmenu-wrapper {
  .h100(); 
}

.pre-wrap {
  white-space: pre-wrap;
}

a.inline-action {
  text-decoration-skip-ink: none;
  text-decoration: underline dashed;
  cursor: pointer;
  display: inline-flex
}

.interactive {
  cursor: pointer;
}

.backward-outline {
  outline: solid 1px @borderColor;
  outline-offset: -1*@spaceHalf;
}

.hidden-visibility {
  visibility: hidden;
}

.border-none {
  border: none !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}


.flex-max {
  flex-grow: 9999;
}

.flex-min {
  flex-grow: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
.code {
  font-family: monospace;
  color: darkred;
}

.tag {
  display: inline-flex;
  align-items: baseline;
  padding: @spaceMini @spaceHalf;
  font-size: 0.7rem;
  border-radius: 3px;
  line-height: 1;
  color: @colorTextOpposite;  
  background-color: @primary;
  &:after {    
    box-shadow: 0 0 0 250px #522d5b;
  }

  .icon:nth-of-type(1) {
    margin-inline-end: @spaceMini;
    color: inherit
  }
}

.pre-line {
  white-space: pre-line;
}

.space-between {
  justify-content: space-between;
}

.locale-indicator {
  margin-top: @spaceMicro; //hack
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 0;
}

i.fa-solid {
  font-style: normal;
  &::before {
    width: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
//  width: 1.25em; //hack to prevent oversized reset icons
}

code {
  font-size: .85em;
  color: darken(@secondary, 40%);
  background-color: fade(@secondary, 10%);
  border: solid 1px @secondary;
  border-radius: @spaceMini;
  padding: 0 @spaceMini;
}