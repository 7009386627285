.map {
  contain: content;
  color: blue;
  filter: @canvasFilter
}

.radius-overlay {
  position: absolute;
  width: 1px;
  height: 1px;
  
  border-radius: 100%;
  background: rgba(255,255, 255,.1);
  box-shadow: rgba(0,0,0, .3) 0px 0px 0px 99999px;
  filter: blur(5px);
}

.point-marker {
  position: absolute;
  transform: translate(4px, -19px);

  .pin {
    transform: translate(-50%,-50%); 
    left: 50%;
    top: 0%; 
    position: absolute;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -1.25rem 0 0 -1.25rem;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 0.5s;
    background-color: @secondary;
    z-index: 3;
    &:after {
      content: attr(data-name);
      width: 1.25rem;
      height: 1.25rem;
      margin: 0.3125rem;
      background: @primary;
      position: absolute;
      border-radius: 50%;
      transform: rotate(45deg);
      color: @colorTextOpposite;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: .5rem 0px 0px -.8rem;
    transform: rotateX(55deg);
    z-index: 1;    
    &:after {
      content: "";
      border-radius: 50%;
      height: 2.5rem;
      width: 2.5rem;
      position: absolute;
      margin: -.5rem 0px 0px -.8rem;
      animation: pulsate 1s ease-out;
      animation-iteration-count: infinite;
      opacity: 0.0;
      box-shadow: 0 0 1px 2px #89849b;
      animation-delay: 1.1s;
    }
  }
  .details {
    background: rgba(255,255,255,0.9);
    color: @colorText;
    font-size: .9em;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0.25rem .5rem;
    /* margin-left: 10px; */
    position: absolute;
    top: -1.25rem;
    left: -0.3125rem;
    padding-left: 1.25rem;
    text-align: center;
    z-index: 2;
    min-height: 2.3rem;
    max-height: 2.3rem;
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 125%;
    animation: slowshow 0.5s cubic-bezier(1,0,1,0);

    .vertical-cut {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 -@spaceMini @spaceMini white inset;
    }
  }
}

.driver-marker {
  position: absolute;
  border-left: solid 2px black;
  min-height: 30px;
  margin-left: 15px;
  
  .flag-plate {
    border: solid 1px black;
    padding: 0 3px;
    background: black;
    color: white;
    font-weight: bold;
    cursor: pointer;

    &:hover {      
      opacity: 0.3;
    }
  }
}

.dashed-b {
  background: repeating-linear-gradient(
    -55deg,
    #222,
    #222 10px,
    #333 10px,
    #333 20px
  );
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-200px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }  
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.pigeon-overlays {
  overflow: clip;
  contain: content;
}