.tab-view {
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .headers {
    display: flex;
    border-bottom: 0;
    
    .labels {
      .noselect;
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
    }
    
    .actions {          
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .action {
        cursor: pointer;
        padding: @spaceMini @spaceHalf;

        &:hover {
          .icon {
            color: @backgroundPrimaryButton;
          }
        }
      }      
    }

    .tab-header {
      .activeElement();
      padding: @spaceMini @spaceHalf;
      background-color: if(@DARK_MODE,  #fff1, #fff8);
      white-space: nowrap;

      &.active {
        background-color: @backgroundButton;
        color: if(@DARK_MODE, @colorText, @colorTextOpposite);
        box-shadow: 0.25rem 0.25rem 0px rgba(0,0,0,.1);
        &:hover {
          background-color: @backgroundButtonHovered;
        }
      }
      &:hover {        
        background-color:  if(@DARK_MODE, #fff4, @backgroundPanelButtonHovered);
      }

      &.highlighted {
        animation: flashes 1s linear;
        background-color: @secondary;         
        
        &:hover {
          background-color: darken(@primary,10%); 
        }
      }
    }
  }

  .dynamic-tab-body {
    flex-grow: 1;

    // Scroll performance boost
    will-change: transform;
    transform: translate3d(0, 0, 0);

    background: @background;
    border: solid @borderSize @borderColor;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .tab {
      contain: content;
      overflow: hidden;
      position: absolute;     
      height: inherit;
      width: inherit;
    }
  }
}