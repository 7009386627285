/*
 * @file about.less
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 6:23:06 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */

 .modal.about-window, .modal.about-window.isActive {
  //background-color: @primary !important;
  color: @colorTextOpposite;
  outline: none !important;
  overflow: hidden;
  box-sizing: border-box;

  background-color: @primary;
  background: radial-gradient(circle,  @secondary 15%, @primary 100%);
  background-size: 500%;

  .icon, .title-text, a, .action {
    color: @colorTextOpposite !important; 
  }

  .modal-content {
    .footer {
      border: none;
    }
  }
}

 .stats-title {
    white-space: nowrap;
    animation: animated-text 1s steps(30, end) 0s 1 normal both;   
 }

 .changelog, .stats-title {
    h1, h2, h3, h4 {
      color: @colorTextOpposite;
    }
 }
 
 .about-view {
   overflow: hidden;
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   height: 100%;

  h4 {
    margin-top: 1em;
  }  
}

.web-hooks {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;

  .hook {
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    padding: @spaceMini;
    background: rgba(0,0,0,.05);
    margin-bottom: 1px;

    .name {      
      .muted();
      font-size: .8em;
      font-weight: bold;
    }    
  }
}

.changelog {
  overflow: auto;
  padding: @spaceHalf;
  height: 100%;
  color: white !important;
  background: rgba(0, 0, 0, 0.4);
  margin-top: @space;
  box-sizing: border-box;
  code {
    white-space: break-spaces;
  }
}
