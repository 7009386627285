// QC: HOLD

#collapse {
  position: absolute;
  top: 0;

  right: if(@isRtl, 0, unset);
  left: if(@isRtl, unset, 0);
 
  min-width: @sidebarWidth;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: block;
  height: @headerHeight;
  background: transparent;
  cursor: pointer;
  margin: 0;

  @media @smartphones {
    min-width: 40px;
  }
}

.collapsed() {
  max-width: 3rem;  
  min-width: auto;
  span.sidebar-label {
    display: none !important;
  }

  @media @smartphones {
    max-width: @sidebarWidth;  
    span.sidebar-label {
      display: flex;
    }
  }
}


@media @smartphones {
  #sidebar-region {
    position: absolute;
    z-index: 1;
    border-right: solid 1px @borderColor;    
  }
  
  #collapse:checked + #sidebar-region {
    transform: translate3d(-1*@sidebarWidth, 0, 0 ); /* X, Y, Z */
  }    
}

#sidebar-region {
  max-width: @sidebarWidth;
  min-width: @sidebarWidth;
  background: @backgroundSidebar;
  height: 100%;
  transition: width 100ms linear, transform .200s ease-in-out;;
  will-change: width;
  overflow-y: auto;

  &.collapsed {
    .collapsed()
  }
   
  .sidebar-buttons {
    transition: outline .15s ease-out, transform .1s ease-in;
    &:focus {
      outline: solid 5px fadeout(@primary, 40%) !important;      
    }
  }

  .sidebar-button {
    .noselect;
    width: 100%;
    cursor: pointer;
    background: if(@DARK_MODE, darken(@backgroundSidebar, 5%), lighten(@backgroundSidebar, 40%));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .inner {
      padding: @space;
      display: flex;
      justify-content: center;
      align-items: center;

      i.fa-solid {                
        min-width: 18px;
      }
      
      span.sidebar-label {        
        color: @colorText;
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0;
        margin-inline-end: @spaceHalf; 
        margin-inline-start: @spaceHalf;
      }
    }


    &:hover , &.focused {
      background: if(@DARK_MODE, lighten(@backgroundSidebar, 10%), lighten(@backgroundSidebar, 45%));
      .inner {
        span {
          color: @colorText;
        }
      }
    }
    
    &.active {
      background: @background;
      outline: none;

      &:hover {
        background: @background;
      }
      &:active {
        box-shadow: none;
      }
    }

    @keyframes light {
        from {background-color: @backgroundWarning;}
        to {background-color: bold;}
    }

    &.highlighted {
      animation: flashes 1s linear;
      background-color: @primary; 
      
      .icon {
        color: @colorTextOpposite;
      }

      span.sidebar-label {
        color: @colorTextOpposite;
      }
      
      &:hover {
        background-color: darken(@primary,10%); 
      }
    }

    @keyframes alert {
      from {
        color: @backgroundError;
        background-color: @backgroundWarning;
        i {
          color: @backgroundError;
        }
      }
      to {
          background-color: inherit;
          color: inherit;
          i {
            color: inherit;
          }
      }
    }

    &.alert {
      background: inherit;
      animation-name: alert;
      animation-duration: 0.2s;
      animation-iteration-count: infinite;
      color: @backgroundError;
      i {
        color: @backgroundError;
      }

    }

    &.disabled, &.disabled:hover, &.disabled:active {
      background: lighten(@backgroundSidebar, 5%);
      cursor: not-allowed;
      color: @colorTextMuted;      
      .fa {
        color: @colorTextMuted;
      }
    }
  }
}
